import React from 'react';
import PropTypes from 'prop-types';
import { graphql, Link } from 'gatsby';
import { Container, Breadcrumb, BreadcrumbItem } from 'reactstrap';
import Helmet from 'react-helmet';
import Layout from '../../components/Layout';
import PageHeading from '../../components/PageHeading/PageHeading';
import styles from '../../css/news.module.css';
import NewsPostsSection from '../../components/NewsPostsSection/NewsPostsSection';

class AnnouncementsPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { data } = this.props;
    const { edges: posts } = data.allWpPost;

    const announcementPosts = posts
      .filter(
        ({ node: post }) => post.categories.nodes[0].slug === 'announcements'
      )
      .map(({ node: post }) => post);

    return (
      <Layout>
        <Helmet
          title="VEYM | Announcements"
          meta={[
            {
              name: 'description',
              content:
                "VEYM Announcements - View all of VEYM's latest updates and announcements!",
            },
          ]}
        />
        <div className={styles.announcementsHeading}>
          <PageHeading>Announcements</PageHeading>
        </div>
        <Container>
          <Breadcrumb>
            <BreadcrumbItem>
              <Link to="/">Home</Link>
            </BreadcrumbItem>
            <BreadcrumbItem>
              <Link to="/news">News</Link>
            </BreadcrumbItem>
            <BreadcrumbItem active>Announcements</BreadcrumbItem>
          </Breadcrumb>
        </Container>
        <br />
        <br />
        <br />

        <div className={styles.news}>
          <NewsPostsSection
            posts={announcementPosts}
            categoryTitle="Announcements"
            pageSizeDesktop={10}
            pageSizeMobile={10}
            desktopView="list"
          />
          <br />
        </div>
        <br />
        <br />
      </Layout>
    );
  }
}

AnnouncementsPage.propTypes = {
  data: PropTypes.shape({
    allWordpressPost: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
};
export default AnnouncementsPage;

export const pageQuery = graphql`
  query AnnouncementsPageQuery {
    allWpPost(sort: { fields: date, order: DESC }) {
      edges {
        node {
          title
          id
          slug
          status
          excerpt
          date(formatString: "MMMM DD, YYYY")
          featuredImage {
            node {
              localFile {
                childImageSharp {
                  fluid(maxWidth: 800) {
                    src
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
          uri
          tags {
            nodes {
              slug
              name
            }
          }
          categories {
            nodes {
              slug
              name
            }
          }
        }
      }
    }
  }
`;

export const frontmatter = {
  title: 'Announcements',
  category: 'pages',
  path: '/news/announcements',
  description: 'Full list of TNTT Announcements',
  tags: 'news, announcements',
};
